import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import Navigation from "../components/Navigation";
import Sidebar from "../components/Sidebar";
import Container from "../components/Container";


const StyledContainer = styled(Container)`
    display: flex;
    position: relative;
    
    @media (max-width: ${props => props.theme.screen.md}) {
    }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Contact us"/>
    <Navigation/>
    <StyledContainer>
      <Sidebar/>
      <div style={{width: "100%"}}>
        <h1>Contact us</h1>
        <script type="text/javascript"
                src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
        <style type="text/css" media="screen, projection">
          @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
        </style>
        <iframe title="Feedback Form" className="freshwidget-embedded-form" id="freshwidget-embedded-form"
                src="https://newaccount1614598377401.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&searchArea=no"
                scrolling="no" height="500px" width="100%" frameBorder="0">
        </iframe>
      </div>
    </StyledContainer>
  </Layout>
)

export default IndexPage
